import BaseService from "@/services/base.service";
import {
    deleteToken,
    setToken,
    setUserInfo,
    deteleUserInfo,
} from "@/services/axios";
import store from "@/store/index";

const API_URL = process.env.VUE_APP_API_URL;

class AuthService extends BaseService {
    loginByPhone(phone: string, password: string, calling_code: string) {
        return this.post(API_URL + "/api/session/login", {
            phone,
            password,
            calling_code,
        }).then((response) => {
            const status = response.data.status;
            if (status === 200) {
                const paramInfo = { ...response.data.data };
                delete paramInfo.token;

                setToken(response.data.data.token);
                store.dispatch("handleLogin");
                setUserInfo(paramInfo);
            }

            return response.data;
        });
    }

    loginByEmail(email: string, password: string) {
        return this.post(API_URL + "/api/session/login-email", {
            email,
            password,
        }).then((response) => {
            const status = response.data.status;
            if (status === 200) {
                const paramInfo = { ...response.data.data };
                delete paramInfo.token;

                setToken(response.data.data.token);
                store.dispatch("handleLogin");
                setUserInfo(paramInfo);
            }

            return response.data;
        });
    }

    loginByGoogle(id_token: string, type: string) {
        return this.post(API_URL + "/api/session/social-signin", {
            id_token,
            type,
        }).then((response) => {
            const status = response.data.status;
            if (status === 200) {
                const paramInfo = { ...response.data.data };
                delete paramInfo.token;

                setToken(response.data.data.token);
                store.dispatch("handleLogin");
                setUserInfo(paramInfo);
            }

            return response.data;
        });
    }

    sendOTPToEmail(type: string, email: string, is_resend: boolean, g_recaptcha_response: string,recaptcha_action?: string, user_id?: number) {
        return this.post(API_URL + "/api/otp/send-email", { type, email, is_resend, g_recaptcha_response,recaptcha_action, user_id}).then(
            (response) => {
                return response.data;
            }
        );
    }

    sendOTPToPhone(
        type: string,
        phone: string,
        calling_code: string,
        country_code?: string,
        g_recaptcha_response?: string,
        recaptcha_action?: string,
        is_resend?: boolean,
        user_id?: number,
    ) {
        return this.post(API_URL + "/api/otp/send-phone", {
            type,
            phone,
            calling_code,
            country_code,
            g_recaptcha_response,
            recaptcha_action,
            is_resend,
            user_id
        }).then((response) => {
            return response.data;
        });
    }

    changePassWord(
        current_password: string,
        new_password: string,
        confirm_password: string
    ) {
        return this.post(API_URL + "/api/user/change-password", {
            current_password,
            new_password,
            confirm_password,
        }).then((response) => {
            return response.data;
        });
    }

    resetPassWord(password: string, code: string) {
        return this.post(API_URL + "/api/session/reset-password", {
            password,
            code,
        }).then((response) => {
            return response.data;
        });
    }

    getUser() {
        return this.get(API_URL + "/session/api/user").then(({ data }) => data);
    }

    register(
        firstName: string,
        lastName: string,
        fullName: string,
        calling_code: string,
        phone: string,
        password: string,
        code: string,
        dob: string,
        nationality: string,
        gender: string,
        platform_slug: string,
        email?: string,
        utm_campaign?: string,
        utm_content?: string,
        utm_medium?: string,
        utm_source?: string,
        utm_term?: string
    ) {
        return this.post(API_URL + "/api/session/register", {
            first_name: firstName,
            last_name: lastName,
            full_name: fullName,
            calling_code,
            phone,
            password,
            code,
            dob,
            nationality,
            gender,
            platform_slug,
            email,
            utm_campaign,
            utm_content,
            utm_medium,
            utm_source,
            utm_term
        }).then((response) => {
            const status = response.data.status;
            if (status === 200) {
                const paramInfo = { ...response.data.data };
                delete paramInfo.token;
                setToken(response.data.data.token);
                store.dispatch("handleLogin");
                setUserInfo(paramInfo);
            }

            return response.data;
        });
    }

    forgotPasswordByPhone(phone: string, code: string) {
        return this.post(API_URL + "/api/session/forgot-password", {
            phone,
            code,
        }).then((response) => {
            return response.data;
        });
    }

    apiCheckOTPByPhone(
        code: string,
        type: string,
        phone: string,
        calling_code: string
    ) {
        return this.post(API_URL + "/api/otp/check-phone", {
            code,
            type,
            phone,
            calling_code,
        }).then((response) => {
            return response.data;
        });
    }

    apiCheckOTPByEmail(code: string, type: string, email: string) {
        return this.post(API_URL + "/api/otp/check-email", {
            code,
            type,
            email,
        }).then((response) => {
            return response.data;
        });
    }

    forgotPasswordByEmail(email: string) {
        return this.post(API_URL + "/api/session/forgot-password-email", {
            email,
        }).then((response) => {
            return response.data;
        });
    }

    logout() {
        deleteToken();
        deteleUserInfo();
        // return this.post(API_URL+'/session/api/logout', {}).then(() => deleteToken());
    }

    addVoucher(code: string) {
        return this.post(API_URL + "/voucher/add", { code }).then(
            (response) => {
                return response.data;
            }
        );
    }

    getBalance() {
        return this.post(API_URL + "/api/user/balance", "").then(
            ({ data }) => data
        );
    }

    checkMail(email: string) {
        return this.post(API_URL + "/api/session/validate-email", {
            email,
        }).then((response) => response.data);
    }

    checkPhone(calling_code: string, phone: string) {
        return this.post(API_URL + "/api/session/validate-phone", {
            phone,
            calling_code: calling_code.replace(/\+/g, ""),
        }).then((response) => response.data);
    }

    checkOTPByEmail(code: string, type: string, email: string) {
        return this.post(API_URL + "/api/otp/verify-email", {
            code,
            type,
            email,
        }).then((response) => response.data);
    }

    checkOTPByPhone(code: string, type: string, phone: string) {
        return this.post(API_URL + "/api/session/validate-phone", {
            code,
            type,
            phone,
        }).then((response) => response.data);
    }

    getListCart() {
        return this.get(API_URL + "/api/carts").then(({ data }) => data);
    }

    saveListCart(param) {
        return this.post(API_URL + "/api/carts/save", param).then(
            ({ data }) => data
        );
    }

    removeListCart() {
        return this.delete(API_URL + "/api/carts/delete").then(
            ({ data }) => data
        );
    }
    // send OTP register
    sendBoth(
        type: string,
        email: string,
        phone: string,
        calling_code: string,
        country_code: string
    ) {
        return this.post(API_URL + "/api/otp/send-both", {
            type,
            email,
            phone,
            calling_code,
            country_code,
        }).then((response) => {
            return response.data;
        });
    }

    checkBoth(
        type: string,
        email: string,
        phone: string,
        calling_code: string,
        code: string
    ) {
        return this.post(API_URL + "/api/otp/check-both", {
            type,
            email,
            phone,
            calling_code,
            code,
        }).then((response) => {
            return response.data;
        });
    }

    updateLocale(locale_code: string) {
        return this.post(API_URL + "/api/user/update-locale", {
            locale_code
        }).then((response) => {
            return response.data;
        });
    }

    verifyDeactivateUser(
        user_id: string,
        current_password: string,
        confirm_password: string,
        type:string,
        g_recaptcha_response: string,
        recaptcha_action?: string
    ){
        return this.post(API_URL + "/api/user/verify-deactivate-user", {
            user_id,
            current_password,
            confirm_password,
            type,
            g_recaptcha_response,
            recaptcha_action
        }).then((response) => {
            return response.data;
        });
    }

    deleteAccountUser(
        user_id: number
    ){
        return this.post(API_URL + "/api/user/deactivate-user", {
            user_id
        }).then((response) => {
            return response.data;
        });
    }

    verifyOtp(
        code: number,
        type: string
    ){
        return this.post(API_URL + "/api/user/verify-otp-deactivate", {
            code,
            type,
        }).then((response) => {
            return response.data;
        });
    }

    verifyAccount(user_id: number, current_password: string, confirm_password: string) {
        return this.post(API_URL + "/api/user/verify-account", {
            user_id,
            current_password,
            confirm_password
        }).then((response) => {
            return response.data;
        });
    }
}

export default new AuthService();
