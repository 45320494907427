<template>
  <div :class="`${classContainer} form-custom`">
    <b-form
      @submit="onSubmit"
      @reset="onReset"
      :data-synerise.attr="dataSynerise"
    >
      <template v-for="(item, index) in formItem">
        <!-- filed input -->
        <b-form-group
          class="group-input"
          v-if="item.type === 'input' && item.isShow !== false"
          :key="index"
          :id="item.idFormGroup || ''"
          :label-for="item.for"
          :description="item.description && getTranslatedText(item.description)"
        >
          <template #label>
            <span v-html="item.label && getTranslatedText(item.label)"></span>
            <span
              class="obligatory"
              v-if="
                (item.required && !item.hideIconRq) || item.showIconRequired
              "
              >*</span
            >
          </template>
          <div
            :class="`b-form-input ${item.typeInput} ${
              $store.state.isRtl ? 'isRtl' : ''
            }`"
          >
            <b-form-input
              :data-synerise.attr="item.synerise"
              :id="item.id"
              :type="
                item.typeInput === 'password' ? item.typeShow : item.typeInput
              "
              :placeholder="
                item.placeholder && getTranslatedText(item.placeholder)
              "
              :required="item.required"
              :disabled="item.disabled"
              :name="item.name"
              :value="dataForm[`${item.name}`]"
              :state="item.state"
              :class="item.typeInput === 'number' ? 'form-input-number' : ''"
              @blur="(event) => onBurInput(event)"
              @input="(value) => onChangeValue(value, item.name)"
              @keydown="(event) => onCheckKeyPress(event, item)"
              :autocomplete="item.useSuggestedPass ? item.useSuggestedPass : 'on'"
            >
            </b-form-input>
            <span
              v-if="
                item.typeInput === 'password' &&
                dataForm[`${item.name}`].length > 0
              "
              class="text-show-password"
              @click="onToggleShowPassword(item.name)"
              >
              <img v-if="item.showIconEye" class="" src="@/assets/images/common/eye-open.svg"/>
              <span v-else>{{ getTranslatedText("Show") }}</span></span
            >
          </div>
          <slot v-if="item.hasVerify" name="buttonVerify" />
          <b-form-invalid-feedback :state="item.state">
            {{ getTranslatedText(item.textInvalid) }}
          </b-form-invalid-feedback>
          <b-form-valid-feedback :state="item.state">
            {{ getTranslatedText(item.textValid) }}
          </b-form-valid-feedback>
        </b-form-group>

        <b-form-group
          v-if="item.type === 'input-area'"
          :key="index"
          :label="`${item.label && getTranslatedText(item.label)}${
            (item.required && !item.hideIconRq) || item.showIconRequired
              ? '*'
              : ''
          }`"
          :id="item.idFormGroup || ''"
          :label-for="item.for"
          :description="item.description && getTranslatedText(item.description)"
        >
          <div
            :class="`b-form-input ${item.typeInput} ${
              $store.state.isRtl ? 'isRtl' : ''
            }`"
          >
            <b-form-textarea
              :data-synerise.attr="item.synerise"
              :id="item.id"
              :type="
                item.typeInput === 'password' ? item.typeShow : item.typeInput
              "
              :placeholder="
                item.placeholder && getTranslatedText(item.placeholder)
              "
              :required="item.required"
              :name="item.name"
              :value="dataForm[`${item.name}`]"
              :state="item.state"
              :class="item.typeInput === 'number' ? 'form-input-number' : ''"
              @blur="(event) => onBurInput(event)"
              @input="(value) => onChangeValue(value, item.name)"
            >
            </b-form-textarea>
            <span
              v-if="
                item.typeInput === 'password' &&
                dataForm[`${item.name}`].length > 0
              "
              class="text-show-password"
              @click="onToggleShowPassword(item.name)"
              >{{ getTranslatedText("Show") }}</span
            >
          </div>
          <b-form-invalid-feedback :state="item.state">
            {{ getTranslatedText(item.textInvalid) }}
          </b-form-invalid-feedback>
          <b-form-valid-feedback :state="item.state">
            {{ getTranslatedText(item.textValid) }}
          </b-form-valid-feedback>
        </b-form-group>

        <!-- filed input datepicker   -->
        <b-form-group
          v-if="item.type === 'datepicker'"
          class="form-datapicker"
          :class="{ isRtl: $store.state.isRtl }"
          :key="index"
          :label="`${item.label && getTranslatedText(item.label)}${
            (item.required && !item.hideIconRq) || item.showIconRequired
              ? '*'
              : ''
          }`"
          :label-for="item.for"
          :description="item.description && getTranslatedText(item.description)"
        >
          <b-input-group class="mb-3">
            <b-form-input
              :id="item.id"
              :name="item.name"
              :required="item.required"
              :placeholder="
                $store.state.isRtl ? 'شهر / سنة / يوم' : 'DD/MM/YYYY'
              "
              :value="dataForm[`${item.name}`]"
              @input="(value) => onChangeValueDate(value, item.name)"
              @blur="(event) => onBurInput(event)"
              type="text"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="item.valueDatepicker"
                :min="item.min"
                :max="item.max"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
                :locale="$store.state.isRtl ? 'ar-EG' : 'en-US'"
                v-bind="labels[$store.state.isRtl ? 'ar-EG' : 'en-US'] || {}"
                :aria-controls="item.id"
                :hide-header="true"
                @input="(value) => onChangeValueDatePicker(value, item.name)"
                button-only
                right
                @context="onContext"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-form-invalid-feedback :state="item.state">
              {{ getTranslatedText(item.textInvalid) }}
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="item.state">
              {{ getTranslatedText(item.textValid) }}
            </b-form-valid-feedback>
          </b-input-group>
        </b-form-group>

        <!-- filed input date -->

        <b-form-group
          v-if="item.type === 'input-date'"
          :key="index"
          :label="`${item.label && getTranslatedText(item.label)}${
            (item.required && !item.hideIconRq) || item.showIconRequired
              ? '*'
              : ''
          }`"
          :label-for="item.for"
          :description="item.description && getTranslatedText(item.description)"
        >
          <b-form-input
            type="date"
            class="form-input-date"
            :data-synerise.attr="item.synerise"
            :id="item.id"
            :placeholder="item.placeholder"
            :required="item.required"
            :value="dataForm[`${item.name}`]"
            :state="item.state"
            :name="item.name"
            :min="item.min"
            :max="item.max"
            @blur="(event) => onBurInput(event)"
            @input="(value) => onChangeValue(value, item.name)"
          >
          </b-form-input>
          <b-form-invalid-feedback :state="item.state">
            {{ getTranslatedText(item.textInvalid) }}
          </b-form-invalid-feedback>
          <b-form-valid-feedback :state="item.state">
            {{ getTranslatedText(item.textValid) }}
          </b-form-valid-feedback>
        </b-form-group>

        <!-- filed input invalid -->
        <b-form-group
          v-if="item.type === 'input-invalid'"
          :key="index"
          :label="`${item.label && getTranslatedText(item.label)}${
            (item.required && !item.hideIconRq) || item.showIconRequired
              ? '*'
              : ''
          }`"
          :id="item.idFormGroup || ''"
          :label-for="item.for"
          :description="item.description && getTranslatedText(item.description)"
        >
          <b-form-input
            :data-synerise.attr="item.synerise"
            :id="item.id"
            :type="item.typeInput"
            :placeholder="item.placeholder"
            :required="item.required"
            :value="dataForm[`${item.name}`]"
            :state="false"
            @blur="(event) => onBurInput(event)"
            @input="(value) => onChangeValue(value, item.name)"
          >
          </b-form-input>
        </b-form-group>

        <!-- filed input group -->
        <b-form-group
          v-if="item.type === 'input-group'"
          :id="item.id"
          :key="index"
          :label-for="item.for"
          :description="item.description && getTranslatedText(item.description)"
          class="form-input-ground"
        >
          <template #label>
            <span v-html="item.label && getTranslatedText(item.label)"></span>
            <span
              class="obligatory"
              v-if="
                (item.required && !item.hideIconRq) || item.showIconRequired
              "
              >*</span
            >
          </template>
          <b-input-group
            :class="{
              inputGroupCustom: item.inputPhoneCountry,
              'is-invalid': item.state === false,
            }"
          >
            <slot :name="item.nameSlotInputPrepend" />
            <span v-if="item.nameSlotInputPrepend" class="input-prepend" />
            <span v-if="item.inputPhoneCountry" class="line-phone-country" />
            <b-form-input
              :data-synerise.attr="item.synerise"
              :id="item.id"
              :type="item.typeInput"
              :placeholder="item.placeholder"
              :name="item.name"
              :value="dataForm[`${item.name}`]"
              :required="item.required"
              :state="item.state"
              :disabled="item.isDisable"
              :class="item.typeInput === 'number' ? 'form-input-number' : ''"
              @keydown.native="item.typeInput === 'number' ? checkDigit : null"
              @blur="(event) => onBurInput(event)"
              @input="(value) => onChangeValue(value, item.name)"
              :autocomplete="item.useSuggestedPass ? item.useSuggestedPass : 'on'"
            ></b-form-input>
            <slot :name="item.nameSlotInputAppend" />
          </b-input-group>
          <b-form-invalid-feedback :state="item.state" :class="{'have_sub': item.txtSub}">
            <span class="txt_error">{{ getTranslatedText(item.textInvalid) }}</span>
            <span class="txt_sub">{{ getTranslatedText(item.txtSub) }}</span>
          </b-form-invalid-feedback>
          <b-form-valid-feedback :state="item.state">
            {{ getTranslatedText(item.textValid) }}
          </b-form-valid-feedback>
          
        </b-form-group>

        <!-- filed radio button  -->
        <b-form-group
          v-if="item.type === 'radio'"
          :key="index"
          :label="`${
            item.label && item.label && getTranslatedText(item.label)
          }${
            (item.required && !item.hideIconRq) || item.showIconRequired
              ? '*'
              : ''
          }`"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            :class="{ 'form-warrning': $store.state.isUpdateProfile }"
            :data-synerise.attr="item.synerise"
            :id="item.id"
            :options="item.options"
            :name="item.name"
            :aria-describedby="ariaDescribedby"
            :required="item.required"
            :checked="dataForm[`${item.name}`]"
            @input="(value) => onChangeValue(value, item.name)"
          ></b-form-radio-group>
        </b-form-group>

        <!-- filed radio select  -->
        <b-form-group
          v-if="item.type === 'select'"
          :key="index"
          :id="item.idGroup"
          :label-for="item.for"
        >
          <template #label>
            <span v-html="item.label && getTranslatedText(item.label)"></span>
            <span
              class="obligatory"
              v-if="
                (item.required && !item.hideIconRq) || item.showIconRequired
              "
              >*</span
            >
          </template>
          <div :class="{ 'form-warrning': $store.state.isUpdateProfile }">
            <!-- <b-form-select :value="null" v-if="item.placeholder">{{ item.placeholder }}</b-form-select> -->
            <b-form-select
              class="form-input-select"
              :data-synerise.attr="item.synerise"
              :id="item.id"
              :options="item.options"
              :required="item.required"
              :disabled="item.disabled"
              :value="dataForm[`${item.name}`]"
              @input="(value) => onChangeValue(value, item.name)"
            >
            </b-form-select>
            <b-form-invalid-feedback :state="item.state" :class="{'have_sub': item.txtSub}">
              <span class="txt_error">{{ getTranslatedText(item.textInvalid) }}</span>
              <span class="txt_sub">{{ getTranslatedText(item.txtSub) }}</span>
            </b-form-invalid-feedback>
          </div>
        </b-form-group>

        <!-- filed radio check box  -->
        <b-form-group
          v-if="item.type === 'checkBox'"
          :key="index"
          :label="item.label && getTranslatedText(item.label)"
          :id="item.id"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            :options="item.options"
            :aria-describedby="ariaDescribedby"
            :class="item.class"
            :data-synerise.attr="item.synerise"
            :checked="dataForm[`${item.name}`]"
            @input="(value) => onChangeValue(value, item.name)"
          >
          </b-form-checkbox-group>
        </b-form-group>

        <!-- filed checkbox confirm  -->
        <b-form-checkbox
          v-if="item.type === 'confirm'"
          class="checkbox-confirm"
          :data-synerise.attr="item.synerise"
          :disabled="item.isDisable"
          :key="index"
          :id="item.id"
          :checked="dataForm[`${item.name}`]"
          @input="(value) => onChangeValue(value, item.name)"
          ><div v-html="getTranslatedText(item.text)" />
        </b-form-checkbox>

        <!-- filed slot -->
        <slot v-if="item.type === 'slot'" :name="item.nameSlot" />
      </template>
      <!-- filed button  -->
      <slot name="button" />
    </b-form>
  </div>
</template>

<script>
// import "@/assets/scss/common/form/_form.scss";
import { checkDigitCommon } from "../utils";
import LanguageMixin from "@/mixins/language/language-mixin";
import moment from "moment";

export default {
  mixins: [LanguageMixin],
  props: {
    formItem: {
      type: Array,
      default: () => [],
    },
    dataForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    classContainer: {
      type: String,
      default: "",
    },
    dataSynerise: {
      type: String,
    },
  },
  data() {
    return {
      nonNumReg: ["e", "E", "+", "-"],
      labels: {
        "en-US": {
          labelNoDateSelected: "MM/DD/YYYY",
        },
        de: {
          labelPrevDecade: "Vorheriges Jahrzehnt",
          labelPrevYear: "Vorheriges Jahr",
          labelPrevMonth: "Vorheriger Monat",
          labelCurrentMonth: "Aktueller Monat",
          labelNextMonth: "Nächster Monat",
          labelNextYear: "Nächstes Jahr",
          labelNextDecade: "Nächstes Jahrzehnt",
          labelToday: "Heute",
          labelSelected: "Ausgewähltes Datum",
          labelNoDateSelected: "Kein Datum gewählt",
          labelCalendar: "Kalender",
          labelNav: "Kalendernavigation",
          labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren",
        },
        "ar-EG": {
          weekdayHeaderFormat: "narrow",
          labelPrevDecade: "العقد السابق",
          labelPrevYear: "العام السابق",
          labelPrevMonth: "الشهر السابق",
          labelCurrentMonth: "الشهر الحالي",
          labelNextMonth: "الشهر المقبل",
          labelNextYear: "العام المقبل",
          labelNextDecade: "العقد القادم",
          labelToday: "اليوم",
          labelSelected: "التاريخ المحدد",
          labelNoDateSelected: "شهر / يوم / سنة",
          labelCalendar: "التقويم",
          labelNav: "الملاحة التقويم",
          labelHelp: "استخدم مفاتيح المؤشر للتنقل في التواريخ",
        },
      },
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.$emit("onSubmitForm");
    },
    onReset(event) {
      event.preventDefault();
      this.$emit("onResetForm");
    },
    onChangeValue(value, name) {
      let item = { value, name };
      this.$emit("update-form-data", item);
    },
    onChangeValueDatePicker(value, name) {
      value = moment(value).format("DD/MM/YYYY");
      let item = { value, name, isSelectDate: true };
      this.$emit("update-form-data", item);
    },

    onChangeValueDate(value, name) {
      let newValue = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");

      const dayOrMonth = (index) => index % 2 === 1 && index < 4;

      value = newValue
        .split("")
        .map((v, i) => (dayOrMonth(i) ? v + "/" : v))
        .join("");

      let item = { value, name };
      this.$emit("update-form-data", item);
    },
    onBurInput(event) {
      this.$emit("on-bur-input", event);
    },

    checkDigit(event) {
      return checkDigitCommon(event);
    },
    onContext(ctx) {
      this.inputValue = ctx.selectedFormatted;
    },

    onToggleShowPassword(name) {
      this.$emit("onToggleShowPassword", name);
    },

    onCheckKeyPress(event, item) {
      if(item.isRestrictSpace && event.keyCode === 32) {
        event.preventDefault()
      } else if (item.isRestrictSpecChar && (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57)) {
        event.preventDefault()
      } else {
        return false
      }
    }
  },
};
</script>

<style scoped lang="scss" src="@/assets/scss/common/form/_form.scss" />
