<template>
    <div class="main" id="main-id">
        <div v-if="paymentType === 'stripe'" id="payment-stripe-id">
        <BannerCustom title=""/>
        <div :class="!isLoadingForm ? 'payment__loading' : 'payment'">
            <div v-if="!isLoadingForm" class="loading"></div>
            <div class="payment__header t-transform-cap">
                {{ getTranslatedText("Payment Information") }}
            </div>
            <div class="payment__body">
                <div class="payment__action">
                    <div class="action_btn">
                        <button class="credit-button" :class="activeCredit" @click="showCredit()">{{ getTranslatedText("Credit Card/Debit Card") }}</button>
                        <button v-if="isApplePay" class="credit-button apple-pay-btn" :class="activeApplePay" @click="showApplePay()">{{ getTranslatedText("Apple Pay") }}</button>
                    </div>
                </div>
                <div class="mess-amount">{{ getTranslatedText(messageAmount) }}</div>
                <div v-if="activeCredit" class="payment__content">
                    <div class="form-card">
                        <div id="payment-element"></div>
                        <div id="message-error"></div>
                        <div class="line"></div>
                        <div class="pay">
                            <div class="title">
                                <span>{{ getTranslatedText("Amount payable") }}</span>
                                <img width="24" height="24" src="@/assets/images/payment/bill.svg" alt="" />
                            </div>
                            <div class="amount-total">
                                {{ getTranslatedText(currencyCode) + ' ' + formatLocalePrice(dataPayment.totalAmountPayment * exchangeRate) }}
                            </div>
                        </div>
                    </div>
                    <div class="payment__card">
                        <div class="payment__card__head">
                            <img width="44" height="36" src="@/assets/images/payment/code.svg" alt="" />
                            <img width="34" height="18" src="@/assets/images/payment/vector.svg" alt="" />
                        </div>
                        <div class="payment__card__footer">
                            <div>
                                <div class="footer-first">
                                    <span>* * * *</span>
                                    <span>{{valueCardNumber ? valueCardNumber : '_ _ _ _'}}</span>
                                </div>
                                <div class="footer-content">
                                    <div>{{valueCardExpiry ? valueCardExpiry : '_ _/_ _'}}</div>
                                    <img v-if="valueCardLogo" width="46" height="28" id="brand-icon1" :src="require(`@/assets/images/payment/${valueCardLogo}.svg`)" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="payment__content apple-pay">
                    <div class="form-card">
                        <div id="payment-element"></div>
                        <div id="message-error"></div>
                        <div class="form-card__app">
                            <span class="title-pay">{{ getTranslatedText("Amount payable") }}</span>
                            <div class="amount-total">
                                {{ getTranslatedText(currencyCode) + ' ' + formatLocalePrice(dataPayment.totalAmountPayment * exchangeRate) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="express-checkout-element"></div>
                <ButtonCustom
                    id="pay-id"
                    :isLoading="isLoadingPayBtn"
                    classBtn="pay-button"
                    typeBtn="button"
                    type="primary"
                    text="Pay now"
                    @onClickButton="purchase('card')"
                />
                <div class="guide">
                    {{getTranslatedText("The total amount payable for your Green Certificate purchase will be automatically deducted from your O! Millionaire wallet. If you have a Voucher Code, you can use it on this checkout page, and the total amount will adjust accordingly.")}}
                </div>
            </div>
         </div>
        </div>
        <div class="checkout-iframe-page" id="checkout-iframe-id" v-else>
        <BannerCustom :title="dataPayment.type === 'add-credit' ? 'add credit' : 'PAYMENT'"/>
        <div class="checkout-iframe">
            <div class="loading-full-page" v-if="isLoadingPage">
                <b-card>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                </b-card>
            </div>
            <template v-else>
                <div class="checkout-iframe__header">
                    {{ getTranslatedText("Payment Information") }}
                </div>
                <div class="mess-amount">{{ getTranslatedText(messageAmount) }}</div>
                <div class="checkout-iframe__content" v-if="dataPaymentCC">
                    <iframe :src="dataPaymentCC.payment_url" frameborder="0"></iframe>
                    <div class="order-details">
                        <div class="order-title">{{ getTranslatedText("ORDER DETAILS") }}</div>
                        <div class="content">
                            <p class="order">{{ getTranslatedText("Order #:") }}</p>
                            <p class="value">{{ dataPaymentCC.reference }}</p>
                        </div>
                        <div class="line"></div>
                        <div class="content">
                            <p class="value t-transform-cap">{{ getTranslatedText("Order Amount") }}</p>
                            <p class="value t-transform-cap">{{ formatLocalePrice(dataPaymentCC.amount) }}</p>
                        </div>
                        <div class="line"></div>
                        <div class="content">
                            <p class="order">{{ getTranslatedText("Total Amount") }}</p>
                            <p class="order">{{ getTranslatedText(currencyCode) }} {{ formatLocalePrice(dataPaymentCC.amount) }}</p>
                        </div>
                    </div>
                </div>
                <div class="line-main"></div>
                <div class="logo">
                    <img loading="lazy" src="@/assets/images/common/cc.webp">
                    <div>
                        <img loading="lazy" src="@/assets/images/common/norton.webp">
                        <img loading="lazy" src="@/assets/images/common/dss.webp">
                    </div>
                </div>
            </template>
        </div>
        <div id="express-checkout-element"></div>
        <div class="apple-pay-button-with-text apple-pay-button-white-with-text" @click="beginApplePaySession">
            <span class="text">Buy with</span>
            <span class="logo"></span>
        </div>
    </div>
        <b-modal
            id="modalNotifyPayment"
            modal-class="modal-fullscreen"
            centered
            body-class="notify__modal-body"
            hide-header
            hide-footer
            :no-close-on-backdrop="true"
            :hide-header-close="true">
            <div v-if="status === 'loading'">
                <span class="loading"></span>
            </div>
            <div v-else-if="status === 'success'">
                <img class="icon-noti" width="70" height="70" src="@/assets/images/payment/success.svg" alt="" />
                <div class="title title-success">{{ getTranslatedText('Payment Successful!') }}</div>
                <div class="sub-title">{{ getTranslatedText('Generating the Green Certificate. Please wait.') }}</div>
                <span class="loader"></span>
            </div>
            <div v-else>
                <img class="icon-noti" width="70" height="70" src="@/assets/images/payment/fail.svg" alt="" />
                <div class="title title-error">{{ getTranslatedText('Payment Unsuccessful!') }}</div>
                <div v-if="messagePaymentFail" class="sub-title">{{ messagePaymentFail }}</div>
                <ButtonCustom
                    :isLoading="isLoadingPayBtn"
                    classBtn="try-button"
                    typeBtn="button"
                    type="primary"
                    text="Try again"
                    @onClickButton="$bvModal.hide('modalNotifyPayment')"
                />
            </div>
        </b-modal>
        <b-toast id="toast-id"
            :toast-class="toastClass"
            solid
            no-close-button
            auto-hide-delay="3000"
            :toaster="toastPosition"
        >
            <div class="toast__content" :class="{ toastContentMobile: isMobileDevice }">
                <div class="toast__content__icon">
                    <img loading="lazy" src="@/assets/images/toast/error.png" />
                </div>
                <div class="toast__content__title errorToast">
                    {{ getTranslatedText(messagePaymentFail) }}
                </div>
            </div>
        </b-toast>
        <Toast :title="toastData.message" :type="toastData.type" :hasVariableTrans="toastData.hasVariableTrans"/>
    </div>
</template>

<script>
import { formatNumberMoney } from "@/components/common/utils";
import LanguageMixin from "@/mixins/language/language-mixin";
import BannerCustom from "@/components/common/banner";
import paymentServive from '@/services/payment.servive';
import ButtonCustom from "@/components/common/button";
import gameService from "@/services/game.service";
import authService from "@/services/auth.service";
import { isArrayEmpty } from "@/components/common/utils";
import Toast from "@/components/common/toast/Toast.vue";

const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;
const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const TIME_PER_GEN = process.env.VUE_APP_TIME_PER_GEN_CERTIFICATE;
const TIME_TOTAL_GEN = process.env.VUE_APP_TIME_TOTAL_GEN_CERTIFICATE;
const API_URL = process.env.VUE_APP_API_URL;
const PAYMENT_TYPE = process.env.VUE_APP_PAYMENT;
const VUE_APP_STRIPE_APPLE_PAY = process.env.VUE_APP_STRIPE_APPLE_PAY;
const VUE_APP_STRIPE_GOOGLE_PAY = process.env.VUE_APP_STRIPE_GOOGLE_PAY;
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

export default {
    mixins: [LanguageMixin],
    components: { BannerCustom, ButtonCustom, Toast },
    data () {
        return {
            toastData: {
                message: "",
                type: "success"
            },
            isLoadingPayBtn: false,
            exchangeRate: EXCHANGE_RATE,
            currencyCode: CURRENCY_CODE,
            timeTotalGen: TIME_TOTAL_GEN,
            timePerGen: TIME_PER_GEN,
            countDown: TIME_TOTAL_GEN,
            domainUrl: API_URL,
            paymentType: PAYMENT_TYPE,
            valueCardNumber: null,
            valueCardExpiry: null,
            valueCardLogo: null,
            messagePaymentFail: null,
            dataPurchase: null,
            status: null,
            isLoadingForm: false,
            paramCheckout: null,
            expressCheckoutElement: null,
            reference: null,
            paymentElement: null,
            stripeElements: null,
            code: [],
            activeCredit: 'active',
            activeApplePay: '',
            isApplePay: false,
            dataPayment: null,
            isMobileDevice: false,
            toastPosition: "b-toaster-bottom-right",
            toastClass: "toast-custom",
            screenWidth: window.innerWidth,
            messageAmount: null,
            isLoadingPage: false,
            dataPaymentCC: null,
            isShowConsent: IS_SHOW_CONSENT,
        }
    },
    created() {
        if (this.screenWidth <= 768) {
            this.isMobileDevice = true;
            this.toastPosition = "b-toaster-top-full";
            this.toastClass = "toast-custom-mobile";
        } else {
            this.isMobileDevice = false;
            this.toastPosition = "b-toaster-bottom-right";
            this.toastClass = "toast-custom";
        }
        window.addEventListener('resize', this.handleResize)
        this.dataPayment = JSON.parse(localStorage.getItem("totalAmountPayment"));
        this.paramCheckout = JSON.parse(localStorage.getItem("cart_checkout"));
        if(this.paymentType === 'stripe') {
            if (!this.dataPayment) {
                window.location.href = '/checkout'
            }
        } else {
            this.isLoadingPage = true
            this.dataPaymentCC = this.$route.params?.data
            if(this.dataPaymentCC && this.dataPaymentCC.amount < 2) {
                this.dataPaymentCC.amount = 2
            }
            if(!this.dataPaymentCC?.payment_url){
                this.$router.push({name:'Checkout'})
            } else {
                this.isLoadingPage = false
            }
        }
    },
    mounted () {
        if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
            if(document.getElementById("payment-stripe-id")) {
                document.getElementById("payment-stripe-id").style.marginBottom = "200px"
            }
            if(document.getElementById("checkout-iframe-id")) {
                document.getElementById("checkout-iframe-id").style.marginBottom = "300px"
            }
        } else {
            if(document.getElementById("payment-stripe-id")) {
                document.getElementById("payment-stripe-id").style.marginBottom = "0"
            }
            if(document.getElementById("checkout-iframe-id")) {
                document.getElementById("checkout-iframe-id").style.marginBottom = "0"
            }
        }
        this.createAndMountFormElements()
    },
    beforeDestroy () {
        if(this.paymentType === 'stripe') {
            this.paymentElement?.destroy();
        }
        this.expressCheckoutElement?.destroy();
    },
    watch: {
        screenWidth(value) {
            if (value <= 768) {
                this.isMobileDevice = true;
                this.toastPosition = "b-toaster-top-full";
                this.toastClass = "toast-custom-mobile";
            }
            else {
                this.isMobileDevice = false;
                this.toastPosition = "b-toaster-bottom-right";
                this.toastClass = "toast-custom";
            }
        },
    },
    methods: {
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
        handleResize() {
            this.screenWidth = window.innerWidth
        },
        countDownTimer() {
            if (this.countDown <= this.timeTotalGen && this.countDown > 0) {
                this.createCertificate()
                setTimeout(() => {
                    this.countDown -= this.timePerGen
                    this.countDownTimer()
                }, this.timePerGen)
            }
        },
        formatLocalePrice(price) {
            return formatNumberMoney(parseFloat(price));
        },
        createAndMountFormElements() { 
            let type = this.dataPayment.type
            let amount
            if (type === 'checkout') {
                if (this.dataPayment.totalAmountPayment * this.exchangeRate >=2 ) {
                    amount = this.dataPayment.totalAmountPayment * this.exchangeRate * 100
                } else {
                    amount = 2 * 100
                    this.dataPayment.totalAmountPayment = 2 / this.exchangeRate
                    this.messageAmount = 'Not enough amount in your wallet. You must charge at least 2 AED by your card'
                }
            } else {
                amount = this.dataPayment.totalAmountPayment * this.exchangeRate * 100
            }
            const options = {
                mode: 'payment',
                amount: parseFloat(this.formatLocalePrice(amount)),
                currency: this.currencyCode.toLowerCase(),
                paymentMethodCreation: 'manual',
                appearance: {
                    variables: {
                        fontFamily: 'Poppins',
                        fontWeight: '700',
                        borderRadius: '30px',
                    }
                }
            };

            this.stripeElements = this.$stripe.elements(options);
            if(this.paymentType === 'stripe') {
                this.paymentElement = this.stripeElements.create('payment');
                this.paymentElement.mount('#payment-element');
                this.paymentElement.on('blur', this.createToken);
                this.paymentElement.on('ready', () => {
                    this.isLoadingForm = true
                })
            }

            this.expressCheckoutElement = this.stripeElements.create('expressCheckout', {
                buttonType: {
                    googlePay: 'buy',
                },
                buttonTheme: {
                    googlePay: 'white'
                },
                paymentMethods: {
                    applePay: VUE_APP_STRIPE_APPLE_PAY,
                    googlePay: VUE_APP_STRIPE_GOOGLE_PAY
                },
                layout: {
                    maxColumns: 1,
                    maxRows: 0,
                    overflow: 'never'
                },
                paymentMethodOrder: ['googlePay', 'applePay']
            });
            
            this.expressCheckoutElement.mount('#express-checkout-element');
            const expressCheckoutDiv = document.getElementById('express-checkout-element');
            if(this.paymentType === 'stripe') {
                expressCheckoutDiv.style.visibility = 'hidden';
                expressCheckoutDiv.style.height = '0px';
            } else {
                expressCheckoutDiv.style.maxWidth = '300px';
                expressCheckoutDiv.style.margin = '0 auto 30px auto';
                expressCheckoutDiv.style.borderRadius = '30px';
            }
            this.expressCheckoutElement.on('ready', ({availablePaymentMethods}) => {
                if (availablePaymentMethods && availablePaymentMethods.applePay) {
                    this.isApplePay = true
                } else {
                    this.isApplePay = false
                }
            });
            this.expressCheckoutElement.on('confirm', async (event) => {
                const {error: submitError} = await this.stripeElements.submit();
                if (submitError) {
                    return;
                }
                if (event.expressPaymentType === 'google_pay') {
                    this.purchase('google-pay')
                } else {
                    this.purchase('apple-pay')
                }
            })
        },
        setInfoCardNumber() {
            this.createToken()
        },
        beginApplePaySession() {
            let userInfo = JSON.parse(localStorage.getItem("user_info"));            
            const paymentRequest = {
                countryCode: 'AE',
                currencyCode: this.currencyCode,
                total: {
                    label: userInfo.name,
                    amount: parseFloat(this.formatLocalePrice(this.dataPaymentCC.amount))
                },
                supportedNetworks: ['visa', 'masterCard', 'amex'],
                merchantCapabilities: ['supports3DS']
            };
            
            console.log(parseFloat(this.formatLocalePrice('2.9999999999999999999999999999999999999')));
            const session = new ApplePaySession(3, paymentRequest);

            session.onvalidatemerchant = (event) => {
                const validationURL = event.validationURL;
                this.validateMerchanApplePay(validationURL, session)
            };

            session.onpaymentauthorized = (event) => {
                const payment = event.payment;
                this.processPaymentApplePay(payment.token);
            };

            session.begin();
        },
        showCredit() {
            this.activeApplePay = ''
            this.activeCredit = 'active'
            const paymentElementDiv = document.getElementById('payment-element');
            paymentElementDiv.style.visibility = 'visible';
            const messageElementDiv = document.getElementById('message-error');
            messageElementDiv.style.visibility = 'visible';
            const checkoutDiv = document.getElementById("express-checkout-element")
            checkoutDiv.style.visibility = 'hidden'
            checkoutDiv.style.height = '0px';
            const payBtnDiv = document.getElementById("pay-id")
            payBtnDiv.style.visibility = 'visible'
            payBtnDiv.style.height = 'auto';
        },
        showApplePay() {
            this.activeApplePay = 'active'
            this.activeCredit = ''
            const paymentElementDiv = document.getElementById('payment-element');
            paymentElementDiv.style.visibility = 'hidden';
            const messageElementDiv = document.getElementById('message-error');
            messageElementDiv.style.visibility = 'hidden';
            const checkoutDiv = document.getElementById("express-checkout-element")
            checkoutDiv.style.visibility = 'visible'
            checkoutDiv.style.height = 'auto';
            const payBtnDiv = document.getElementById("pay-id")
            payBtnDiv.style.visibility = 'hidden';
            payBtnDiv.style.height = '0px';
        },
        async createToken() {
            try {
                const {error: submitError} = await this.stripeElements.submit();
                if (submitError) {
                    this.valueCardNumber = ''
                    this.valueCardExpiry = ''
                    this.valueCardLogo = ''
                    document.getElementById("message-error").innerHTML = ''
                    return;
                }
                const {paymentMethod, error} = await this.$stripe.createPaymentMethod({elements: this.stripeElements});
                if (error && error.type === "card_error") {
                    this.valueCardNumber = ''
                    this.valueCardExpiry = ''
                    this.valueCardLogo = ''
                    document.getElementById("message-error").innerHTML = error.message
                    return;
                } else if(paymentMethod) {
                    document.getElementById("message-error").innerHTML = ''
                    this.valueCardNumber = paymentMethod.card.last4;
                    let month = paymentMethod.card.exp_month < 10 ? '0' + paymentMethod.card.exp_month.toString() : paymentMethod.card.exp_month.toString()
                    this.valueCardExpiry = month.toString() + '/' + paymentMethod.card.exp_year.toString().slice(2, 4)
                    this.valueCardLogo = paymentMethod.card.brand
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async purchase(paymentMethod) {
            this.isLoadingPayBtn = true
            try {
                let utm = JSON.parse(localStorage.getItem('utm-paramaters'))
                let {utm_campaign, utm_content, utm_medium, utm_source, utm_term} = utm ? utm : {}
                const param = {
                    amount: parseFloat(this.formatLocalePrice(this.dataPayment.totalAmountPayment)),
                    payment_method: paymentMethod,
                    is_fund_deposit: this.dataPayment.type === 'add-credit',
                    reference: null,
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                    utm_term
                }
                const resp = await paymentServive.purchaseStripe(param)
                if(resp.status === 200) {
                    this.dataPurchase = resp.data
                    this.confirmPayment(resp.data.client_secret)
                } else {
                    this.isLoadingPayBtn = false
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async confirmPayment(clientSecret) {
            this.isLoadingPayBtn = true
            try {
                this.status = 'loading'
                const {error: submitError} = await this.stripeElements.submit();
                if (submitError) {
                    this.isLoadingPayBtn = false
                    return;
                }
                const res = await this.$stripe.confirmPayment({
                    elements: this.stripeElements,
                    clientSecret,
                    confirmParams: {
                        return_url: `${this.domainUrl}/stripe-payment/callback/result`,
                    },
                    redirect: "if_required"
                });
                let paymentIntent
                if(res.error) {
                    this.isLoadingPayBtn = false
                    paymentIntent = res.error.payment_intent.id
                    this.isLoadingPayBtn = false
                    this.messagePaymentFail = res.error.message
                    if(this.isApplePay) {
                        this.$bvToast.show('toast-id');
                    } else {
                        this.status = 'fail';
                        this.$bvModal.show("modalNotifyPayment")
                    }
                    return false
                }
                if(res.paymentIntent) {
                    paymentIntent = res.paymentIntent.id
                    this.isLoadingPayBtn = false
                    this.status = 'loading'
                    this.$bvModal.show("modalNotifyPayment")
                    this.callBack(paymentIntent)
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async callBack(paymentIntent) {
            this.status = 'loading'
            this.$bvModal.show("modalNotifyPayment")
            try {
                const res = await paymentServive.callBackStripe(paymentIntent)
                if (res.data) {
                    this.status = res.data.status
                    this.$bvModal.show("modalNotifyPayment")
                    if (res.data.status === 'success') {
                        if(this.dataPayment.type === 'add-credit') {
                            localStorage.removeItem("totalAmountPayment")
                            this.$store.dispatch("setBalance");
                            this.$router.push("/payment/successful")
                        } else {
                            this.getTransaction()
                        }
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async getTransaction() {
            try {
                const resp = await gameService.transact(this.paramCheckout);
                if (resp.status === 200) {
                    this.reference = resp.data.reference
                    this.getReference(resp.data.reference)
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async getReference(reference) {
            try {
                const resp = await gameService.getReference(reference);
                if (resp.status === 200) {
                    resp.data.transactions.map((item, index) => {
                        this.code.push(item.raffle_id)
                    })
                }
                if(!isArrayEmpty(this.code)) {
                    this.countDownTimer()
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async createCertificate() {
            try {
                const resp = await paymentServive.createCertificate(this.code);
                if (resp.status === 200 && resp.data.status) {
                    this.countDown = 0
                    await authService.removeListCart();
                    localStorage.removeItem("list_block");
                    localStorage.removeItem("list_block_tmp");
                    localStorage.removeItem("cart_checkout");
                    localStorage.removeItem("total_items");
                    localStorage.removeItem("referencePayment");
                    localStorage.removeItem("totalAmountPayment");
                    this.$store.dispatch("onChangeAnountCart", 0);
                    this.listCheckouts = [];
                    this.$store.dispatch("setBalance");
                    this.$router.push(`/thank-you?reference=${this.reference}`);
                    window.performance.navigation.TYPE_RELOADED = true
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async validateMerchanApplePay(validationURL, session) {
            try {
                const resp = await paymentServive.validateMerchanApplePay(validationURL)
                if(resp.status === 200) {
                    session.completeMerchantValidation(resp.data);
                } else {
                    session.abort();
                    this.showToast(this.getTranslatedText(resp.message), "error");
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        createFormDataPayment(data) {
            const form = document.createElement('form');
            form.method = 'post';
            form.action = data.payment_url;
            form.name = 'redirect';

            // Create hidden input for encRequest
            const encRequestInput = document.createElement('input');
            encRequestInput.type = 'hidden';
            encRequestInput.name = 'encRequest';
            encRequestInput.value = data.encRequest;
            form.appendChild(encRequestInput);

            // Create hidden input for accessCode
            const accessCodeInput = document.createElement('input');
            accessCodeInput.type = 'hidden';
            accessCodeInput.name = 'access_code';
            accessCodeInput.value = data.access_code;
            form.appendChild(accessCodeInput);

            // Append form to body and submit
            document.body.appendChild(form);
            form.submit();
        },
        async processPaymentApplePay(token) {
            try {
                let utm = JSON.parse(localStorage.getItem('utm-paramaters'))
                let {utm_campaign, utm_content, utm_medium, utm_source, utm_term} = utm ? utm : {}
                const param = {
                    token: token,
                    amount: parseFloat(this.formatLocalePrice(this.dataPayment.totalAmountPayment)),
                    is_fund_deposit: this.dataPayment.type === 'add-credit',
                    reference: null,
                    payment_method: 'apple-pay',
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                    utm_term
                }
                const resp = await paymentServive.processPaymentApplePay(param)
                if(resp.status === 200) {
                    this.createFormDataPayment(resp.data)
                } else {
                    this.showToast(this.getTranslatedText(resp.message), "error");
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }
};
</script>
<style scoped lang="scss" src="@/assets/scss/mobile/payment/_payment.scss"/>
<style scoped lang="scss" src="@/assets/scss/mobile/checkout/_checkout-iframe.scss" />
