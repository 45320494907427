<template>
    <div class="main">
        <BannerCustom title="Frequently Asked Questions" />
        <div class="how-to">
            <div class="how-to__contents">
                <div class="sub-content">
                    <div
                        v-for="(item, index) in listItems"
                        :key="index"
                        class="how-to__list-items"
                        :class="{ active: item.isActive }"
                    >
                        <div
                            @click="handleToggleAnswer(index)"
                            class="how-to__list-item"
                        >
                            <span v-html="getTranslatedText(item.title)" />
                            <img loading="lazy"
                                v-if="!item.isActive && item.content"
                                class="how-to__list-item__title-icon-down"
                                src="@/assets/images/static-pages/plus.svg"
                            />
                            <img loading="lazy"
                                v-if="item.isActive && item.content"
                                class="how-to__list-item__title-icon-up"
                                src="@/assets/images/static-pages/minus.svg"
                            />
                        </div>
                        <div v-if="item.isActive" class="how-to__list-item__content">
                            <div
                                v-for="(value, i) in item.content"
                                :key="i"
                                class="how-to__list-item__content__item"
                            >
                                <div v-if="isScreenMobile && item.step === 3" class="draws-title">
                                    <div>{{ getTranslatedText(value.firstTitle) }}</div>
                                    <div>{{ getTranslatedText(value.secondTitle) }}</div>
                                </div>
                                <div v-else class="sub-title">{{ getTranslatedText(value.subTitle) }}</div>
                                <div v-if="item.step === 3">
                                    <div :class="value.subTitle ? 'draws-guide head' : 'draws-guide'">
                                        <div class="first">{{ getTranslatedText(value.step) }}<br/><span>{{ getTranslatedText(value.text) }}</span></div>
                                        <div class="second up-text"><span class="t-transform-cap">{{ getTranslatedText(value.text0) }}</span> {{ getTranslatedText(value.text1) }}</div>
                                        <div class="last">{{ getTranslatedText(value.text2) }}</div>
                                    </div>
                                    <div class="block" v-if="value.icon">
                                        <img loading="lazy"
                                            :src="require(`@/assets/images/landing-page/${value.icon}`)"
                                            :alt="value.icon"
                                        />
                                        <div class="block__title">
                                            {{ getTranslatedText(value.text3) }}
                                            <span>{{ getTranslatedText(value.text4) }}</span>.
                                        </div>
                                    </div>
                                    <div class="parcitipant">{{ getTranslatedText(value.text5) }}</div>
                                </div>
                                <div v-else-if="item.hasImg" :class="item.isLast ? 'group-last' : ''">
                                    <div :class="item.hasSocial ? 'social group-step' : 'group-step'">
                                        <div class="content" v-for="(data, idx) in (isScreenMobile ? value.listStepsMb : value.listSteps)" :key="idx">
                                            <div class="content-sub">
                                                <div class="group-icon">
                                                    <a v-if="data.link" :href="data.link" target="_blank">
                                                        <div class="circle-icon"><img loading="lazy" :src="require(`@/assets/images/static-pages/${data.icon}`)" :alt="data.icon"/></div>
                                                    </a>
                                                    <div class="circle-icon" v-else><img loading="lazy" :src="require(`@/assets/images/static-pages/${data.icon}`)" :alt="data.icon"/></div>
                                                </div>
                                                <div v-if="data.text" class="text-des" v-html="getTranslatedText(data.text)"></div>
                                                <div class="steps"><div v-if="data.step" :class="data.icon1 ? 'step line' : 'step'">{{ getTranslatedText(data.step) }}</div></div>
                                            </div>
                                            <img v-if="data.icon1 || data.icon2" loading="lazy" :src="require(`@/assets/images/static-pages/${data.icon1 || data.icon2}`)" :alt="data.icon1"/>
                                        </div>
                                    </div>
                                    <div class="text-last">{{ getTranslatedText(value.text)}}
                                        <a class="link-om" v-if="value.link2" :href="'https://'+ value.link2" target="_blank">{{ getTranslatedText(value.link2)}}</a>
                                    </div>
                                </div>
                                <div v-else class="content">
                                    <div class="how-to__list-item__content__item__left">
                                        {{ getTranslatedText(value.step) }}
                                    </div>
                                    <div class="how-to__list-item__content__item__right">
                                        {{ getTranslatedText(value.text) }}
                                        <a class="link-text" href="https://play.google.com/store/apps/details?id=app.omilionaire&pli=1" target="_blank">{{ getTranslatedText(value.text1) }}</a>
                                        {{ getTranslatedText(value.text2) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import languageMixin from "@/mixins/language/language-mixin";
import BannerCustom from "@/components/common/banner";

export default {
    mixins: [languageMixin],
    components: { BannerCustom },
    data() {
        return {
            screenWidth: window.innerWidth,
            isScreenMobile: false,
            listItems: [
                {
                    title: this.getTranslatedText('1. How Can I Contact O! Millionaire Customer Support?'),
                    content: [
                        {
                            subTitle: "You can reach our Customer Support team through three methods:",
                            step: "1:",
                            text: "Call us toll-free at 800-Millions (800 645 54667) from anywhere in the world (check for timings).",
                        },
                        {
                            step: "2:",
                            text: "Send us an email at: support@omillionaire.com"
                        },
                        {
                            step: "3:",
                            text: "· Fill out the contact form on our Contact Us page."
                        }
                    ],
                    isActive: true,
                },
                {
                    title: this.getTranslatedText("2. How Do I Join the O! Millionaire Draw?"),
                    content: [
                        {
                            subTitle: "Joining the O! Millionaire Draw is easy:",
                            step: "1:",
                            text: "Create an account and select your 7 lucky numbers.",
                        },
                        {
                            step: "2:",
                            text: "Alternatively, you can use our mobile apps available on Google Play at",
                            text1: "https://play.google.com/store/apps/details?id=app.omilionaire,",
                            text2: ", ios app will also be available"
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("3. what are the prize breakdown for o! millionaire?"),
                    content: [
                        {
                            subTitle: "O! Millionaire offers a range of prizes:",
                            firstTitle: 'match',
                            secondTitle: 'win aed',
                            step: "Grand Prize",
                            text: "7 OUT OF 7",
                            text0: "over",
                            text1: "AED 100,000,000",
                            text2: "Congratulations, you won the biggest Grand Prize.",
                        },
                        {
                            step: "Second Prize",
                            text: "6 OUT OF 7",
                            text1: "AED 500,000",
                            text2: "One number away, but you’re 500,000 dirhams richer."
                        },
                        {
                            step: "Third Prize",
                            text: "5 OUT OF 7",
                            text1: "AED 5,000",
                            text2: "Great job! Buy more Green Certificates?"
                        },
                        {
                            step: "Fourth Prize",
                            text: "4 OUT OF 7",
                            text1: "AED 500",
                            text2: "Fantastic! That’s still a sweet win!"
                        },
                        {
                            step: "Fifth Prize",
                            text: "3 OUT OF 7",
                            text1: "AED 10",
                            text2: "Sounds great! You have enough for the Secure the Grand Prize",
                            text3: "Guaranteed weekly Raffle ID match: AED",
                            text4: "100,000",
                            text5: "*You are the chosen one out of thousands of participants.",
                            icon: "icon-win3.svg"
                        }
                    ],
                    step: 3,
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("4. Where Can I Find My Raffle ID?"),
                    content: [
                        {
                            subTitle: "To find your Raffle ID:",
                            listSteps: [
                                {
                                    icon: 'phone.svg',
                                    icon1: 'right.svg',
                                    text: '· Navigate to “My Account.”',
                                    step: '1'
                                },
                                {
                                    icon: 'number.svg',
                                    icon1: 'right.svg',
                                    text: '· Select “My Numbers.”',
                                    step: '2'
                                },
                                {
                                    icon: 'dropdown.svg',
                                    text: '· Download your Green Certificate or receive it via SMS or email.',
                                    step: '3'
                                },
                            ],
                            listStepsMb: [
                                {
                                    icon: 'phone.svg',
                                    icon2: 'right.svg',
                                    text: '· Navigate to “My Account.”',
                                    step: '1'
                                },
                                {
                                    icon: 'number.svg',
                                    text: '· Select “My Numbers.”',
                                    step: '2'
                                },
                            ]
                        },
                        {
                            text: "* Your Raffle ID is the 8-character alphanumeric unique codes found on each of your Green Certificate.",
                            listStepsMb: [
                                {
                                    icon: 'dropdown.svg',
                                    text: '· Download your Green Certificate or receive it via SMS or email.',
                                    step: '3'
                                }
                            ]
                        }
                    ],
                    hasImg: true,
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("5. How Much Does a Green Certificate Cost?"),
                    content: [
                        {
                            subTitle: "The Green Certificate for the lucky draw is priced for only AED 25."
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("6. Which Currencies Does O! Millionaire Accept?"),
                    content: [
                        {
                            subTitle: "All transactions are conducted in AED (United Arab Emirates Dirham). For other currencies, the current exchange rate applies depending on your bank currencies."
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("7. How Can I Enter the O! Millionaire Draw?"),
                    content: [
                        {
                            subTitle: "Entering the O! Millionaire Draw is simple:",
                            listSteps: [
                                {
                                    icon: 'phone.svg',
                                    icon1: 'right.svg',
                                    text: '· Create an account at www.omillionaire.com.',
                                    step: '1'
                                },
                                {
                                    icon: 'target.svg',
                                    icon1: 'right.svg',
                                    text: '· Select “Buy Now.”',
                                    step: '2'
                                },
                                {
                                    icon: 'number.svg',
                                    icon1: 'right.svg',
                                    text: '· Pick your 7 lucky numbers.',
                                    step: '3'
                                },
                                {
                                    icon: 'gc.svg',
                                    text: '· Click “Buy the Green Certificate.”',
                                    step: '4'
                                },
                            ],
                            listStepsMb: [
                                {
                                    icon: 'phone.svg',
                                    icon2: 'right.svg',
                                    text: '· Create an account at www.omillionaire.com.',
                                    step: '1'
                                },
                                {
                                    icon: 'target.svg',
                                    text: '· Select “Buy Now.”',
                                    step: '2'
                                },
                            ]
                        },
                        {
                            listSteps: [
                                {
                                    icon: 'money.svg',
                                    icon1: 'left.svg',
                                    text: '· Complete the payment.',
                                    step: '7'
                                },
                                {
                                    icon: 'cart.svg',
                                    icon1: 'left.svg',
                                    text: '· Review your cart.',
                                    step: '6'
                                },
                                {
                                    icon: 'yes.svg',
                                    text: '· Confirm your details.',
                                    step: '5'
                                },
                            ],
                            listStepsMb: [
                                {
                                    icon: 'gc.svg',
                                    icon2: 'left.svg',
                                    text: '· Click “Buy the Green Certificate.”',
                                    step: '4'
                                },
                                {
                                    icon: 'number.svg',
                                    text: '· Pick your 7 lucky numbers.',
                                    step: '3'
                                },
                            ]
                        },
                        {
                            listSteps: [
                                {
                                    icon: 'down.svg',
                                    icon2: 'right.svg',
                                    text: '· Download your Green Certificate.',
                                    step: '8'
                                },
                                {
                                    icon: 'video.svg',
                                    link: process.env.VUE_APP_YOUTUBE_URL,
                                    text: '· Tune in for the Live Draw.',
                                    step: '9'
                                },
                            ],
                            listStepsMb: [
                                {
                                    icon: 'yes.svg',
                                    icon2: 'right.svg',
                                    text: '· Confirm your details.',
                                    step: '5'
                                },
                                {
                                    icon: 'cart.svg',
                                    text: '· Review your cart.',
                                    step: '6'
                                },
                            ]
                        },
                        {
                            listStepsMb: [
                                {
                                    icon: 'down.svg',
                                    icon2: 'left.svg',
                                    text: '· Download your Green Certificate.',
                                    step: '8'
                                },
                                {
                                    icon: 'money.svg',
                                    text: '· Complete the payment.',
                                    step: '7'
                                },
                            ]
                        },
                        {
                            listStepsMb: [
                                {
                                    icon: 'video.svg',
                                    link: process.env.VUE_APP_YOUTUBE_URL,
                                    text: '· Tune in for the Live Draw.',
                                    step: '9'
                                },
                            ]
                        }
                    ],
                    hasImg: true,
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("8. How Do I Check My Winnings?"),
                    content: [
                        {
                            subTitle: "All transactions are conducted in AED (United Arab Emirates Dirham). For other currencies, the current exchange rate applies depending on your bank currencies.",
                            step: "Step 1:",
                            text: "· Watch the live weekly draw on YouTube and Facebook to see the results real-time.",
                        },
                        {
                            step: "Step 2:",
                            text: '· Visit “My Account” on the O! Millionaire website and explore “My Wallet” to view your balance and total winnings.',
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("9. How Can I Add Credit?"),
                    content: [
                        {
                            subTitle: "To add credit:",
                            listSteps: [
                                {
                                    icon: 'phone.svg',
                                    icon1: 'right.svg',
                                    text: '· Sign in or register to www.omillionaire.com.',
                                    step: '1'
                                },
                                {
                                    icon: 'wallet.svg',
                                    icon1: 'right.svg',
                                    text: '· Access “My Wallet.”',
                                    step: '2'
                                },
                                {
                                    icon: 'money.svg',
                                    icon1: 'right.svg',
                                    text: '· Choose “Add Credit.”',
                                    step: '3'
                                },
                                {
                                    icon: 'payment.svg',
                                    text: '· Specify the amount and payment method.',
                                    step: '4'
                                },
                            ],
                            listStepsMb: [
                                {
                                    icon: 'phone.svg',
                                    icon2: 'right.svg',
                                    text: '· Sign in or register to www.omillionaire.com.',
                                    step: '1'
                                },
                                {
                                    icon: 'wallet.svg',
                                    text: '· Access “My Wallet.”',
                                    step: '2'
                                },
                            ]
                        },
                        {
                            listStepsMb: [
                                {
                                    icon: 'payment.svg',
                                    icon2: 'left.svg',
                                    text: '· Specify the amount and payment method.',
                                    step: '4'
                                },
                                {
                                    icon: 'money.svg',
                                    text: '· Choose “Add Credit.”',
                                    step: '3'
                                },
                            ]
                        }
                    ],
                    hasImg: true,
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("10. Can I Transfer Credit?"),
                    content: [
                        {
                            subTitle: "You can transfer credit by following these steps:",
                            listSteps: [
                                {
                                    icon: 'phone.svg',
                                    icon1: 'right.svg',
                                    text: '· Sign in or register to www.omillionaire.com.',
                                    step: '1'
                                },
                                {
                                    icon: 'wallet.svg',
                                    icon1: 'right.svg',
                                    text: '· Go to “My Wallet.”',
                                    step: '2'
                                },
                                {
                                    icon: 'home.svg',
                                    icon1: 'right.svg',
                                    text: '· Choose “User Transfer/Bank Withdrawal.”',
                                    step: '3'
                                },
                                {
                                    icon: 'form.svg',
                                    text: '· Complete the transfer form.',
                                    step: '4'
                                },
                            ],
                            listStepsMb: [
                                {
                                    icon: 'phone.svg',
                                    icon2: 'right.svg',
                                    text: '· Sign in or register to www.omillionaire.com.',
                                    step: '1'
                                },
                                {
                                    icon: 'wallet.svg',
                                    text: '· Go to “My Wallet.”',
                                    step: '2'
                                },
                            ]
                        },
                        {
                            listStepsMb: [
                                {
                                    icon: 'form.svg',
                                    icon2: 'left.svg',
                                    text: '· Complete the transfer form.',
                                    step: '4'
                                },
                                {
                                    icon: 'home.svg',
                                    text: '· Choose “User Transfer/Bank Withdrawal.”',
                                    step: '3'
                                },
                            ]
                        }
                    ],
                    hasImg: true,
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("11. Which Payment Cards Are Accepted?"),
                    content: [
                        {
                            subTitle: "O! Millionaire accepts Visa, MasterCard, UnionPay, and Apple Pay.",
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("12. How Do I Withdraw Winnings?"),
                    content: [
                        {
                            subTitle: "Withdraw winnings via Bank Withdrawal (minimum AED 500):",
                            listSteps: [
                                {
                                    icon: 'phone.svg',
                                    icon1: 'right.svg',
                                    text: '· Navigate to “My Account.”',
                                    step: '1'
                                },
                                {
                                    icon: 'wallet.svg',
                                    icon1: 'right.svg',
                                    text: '· Click on “My Wallet.”',
                                    step: '2'
                                },
                                {
                                    icon: 'home.svg',
                                    icon1: 'right.svg',
                                    text: '· Select “Bank Withdrawal.”',
                                    step: '3'
                                },
                                {
                                    icon: 'form.svg',
                                    text: '· Complete the necessary details.',
                                    step: '4'
                                },
                            ],
                            listStepsMb: [
                                {
                                    icon: 'phone.svg',
                                    icon2: 'right.svg',
                                    text: '· Navigate to “My Account.”',
                                    step: '1'
                                },
                                {
                                    icon: 'wallet.svg',
                                    text: '· Click on “My Wallet.”',
                                    step: '2'
                                },
                            ]
                        },
                        {
                            listStepsMb: [
                                {
                                    icon: 'form.svg',
                                    icon2: "left.svg",
                                    text: '· Complete the necessary details.',
                                    step: '4'
                                },
                                {
                                    icon: 'home.svg',
                                    text: '· Select “Bank Withdrawal.”',
                                    step: '3'
                                }
                            ]
                        }
                    ],
                    hasImg: true,
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("13. Can I Stay Anonymous if I Win the Lucky Draw?"),
                    content: [
                        {
                            subTitle: "Yes, winners have the freedom to choose to remain anonymous or share their O! Millionaire experience. We respect and protect the privacy of our participants, you will still receive your winnings even when you decide to be anonymous.",
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("14. Are Winnings Taxable?"),
                    content: [
                        {
                            subTitle: "No. The UAE does not have personal income tax on winnings or any other form of personal income.",
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("15. What Happens If There Are Multiple Winners in the O! Millionaire Grand Draw?"),
                    content: [
                        {
                            subTitle: 'The Grand Prize will be divided equally',
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("16. How Long Does It Take Before I Can Claim My Winnings?"),
                    content: [
                        {
                            subTitle: 'Winners can immediately claim prizes upon winning by withdrawing them from their O! Millionaire wallet at www.omillionaire.com. Upon balance withdrawal, bank transfers may take 30 to 60 business days. Sometimes, there are unexpected delays from the bank’s end. You can contact us at support@omillionaire.com, and we will attempt to reach out to your bank for you.',
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("17. Does the Color of the Balls Mean Anything?"),
                    content: [
                        {
                            subTitle: 'O! Millionaire has two color combinations: 1-22 is GREEN which symbolizes the Trees (Earth & Environment) and 23-44 is BLUE which symbolizes the WATER. Also, this way, everyone can see the range of numbers just by looking at the color of the balls that come out.',
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("18. How to Change my Profile Details?"),
                    content: [
                        {
                            subTitle: "Withdraw winnings via Bank Withdrawal (minimum AED 500):",
                            listSteps: [
                                {
                                    icon: 'phone.svg',
                                    icon1: 'right.svg',
                                    text: '· Navigate to “My Account.”',
                                    step: '1'
                                },
                                {
                                    icon: 'user.svg',
                                    icon1: 'right.svg',
                                    text: '· Select “Personal Details.”',
                                    step: '2'
                                },
                                {
                                    icon: 'book.svg',
                                    icon1: 'right.svg',
                                    text: '· Change the information that you want to change.',
                                    step: '3'
                                },
                                {
                                    icon: 'target.svg',
                                    text: '· Click the “Save” button.',
                                    step: '4'
                                },
                            ],
                            listStepsMb: [
                                {
                                    icon: 'phone.svg',
                                    icon2: 'right.svg',
                                    text: '· Navigate to “My Account.”',
                                    step: '1'
                                },
                                {
                                    icon: 'user.svg',
                                    text: '· Select “Personal Details.”',
                                    step: '2'
                                },
                            ]
                        },
                        {
                            listStepsMb: [
                                {
                                    icon: 'target.svg',
                                    icon2: 'left.svg',
                                    text: '· Click the “Save” button.',
                                    step: '4'
                                },
                                {
                                    icon: 'book.svg',
                                    text: '· Change the information that you want to change.',
                                    step: '3'
                                }
                            ]
                        }
                    ],
                    hasImg: true,
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("19. What is DOUBLE the Grand Prize?"),
                    content: [
                        {
                            subTitle: 'Select this option if you want to DOUBLE your Grand Prize winnings for only an additional amount of AED 12.50. This feature only applies to the Grand Prize and not on the other prizes.',
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("20. What is SECURE the Grand Prize?"),
                    content: [
                        {
                            subTitle: 'If there are many Grand Prize winners, you all split the winnings, but if you pick “Secure the Grand Prize,” you can keep the WHOLE Grand Prize!',
                        }
                    ],
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("21. How to Redeem Gift Cards?"),
                    content: [
                        {
                            listSteps: [
                                {
                                    icon: 'phone.svg',
                                    icon1: 'right.svg',
                                    text: '· Navigate to “My Account.”',
                                    step: '1'
                                },
                                {
                                    icon: 'wallet.svg',
                                    icon1: 'right.svg',
                                    text: '· Select “My Wallet.”',
                                    step: '2'
                                },
                                {
                                    icon: 'gift.svg',
                                    text: '· Click “Redeem Gift Card.”',
                                    step: '3'
                                }
                            ],
                            listStepsMb: [
                                {
                                    icon: 'phone.svg',
                                    icon2: 'right.svg',
                                    text: '· Navigate to “My Account.”',
                                    step: '1'
                                },
                                {
                                    icon: 'wallet.svg',
                                    text: '· Select “My Wallet.”',
                                    step: '2'
                                },
                            ]
                        },
                        {
                            listSteps: [
                                {
                                    icon: 'target.svg',
                                    icon2: 'left.svg',
                                    text: '· Click “Validate Voucher.”',
                                    step: '5'
                                },
                                {
                                    icon: 'code.svg',
                                    text: '· Enter the code.',
                                    step: '4'
                                },
                            ],
                            listStepsMb: [
                                {
                                    icon: 'code.svg',
                                    icon2: 'left.svg',
                                    text: '· Enter the code.',
                                    step: '4'
                                },
                                {
                                    icon: 'gift.svg',
                                    text: '· Click “Redeem Gift Card.”',
                                    step: '3'
                                }
                            ]
                        },
                        {
                            listStepsMb: [
                                {
                                    icon: 'target.svg',
                                    text: '· Click “Validate Voucher.”',
                                    step: '5'
                                },
                            ]
                        }
                    ],
                    hasImg: true,
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("22. How to Watch the Live Draw?"),
                    content: [
                        {
                            subTitle: "Watch us live every Thursday at 8 pm (GST), where we announce the winning Raffle ID and the winning number combinations LIVE on our Facebook page and YouTube channel.",
                            text: "Also, all the winning numbers and Raffle IDs are posted on our social media platforms and on our website at",
                            listSteps: [
                                {
                                    icon: 'video.svg',
                                    link: process.env.VUE_APP_YOUTUBE_URL
                                },
                                {
                                    icon: 'fb.svg',
                                    link: "https://www.facebook.com/OMillionaire",
                                }
                            ],
                            listStepsMb: [
                                {
                                    icon: 'video.svg',
                                    link: process.env.VUE_APP_YOUTUBE_URL
                                },
                                {
                                    icon: 'fb.svg',
                                    link: "https://www.facebook.com/OMillionaire",
                                }
                            ],
                            link2: "www.omillionaire.com/draws",
                        }
                    ],
                    hasImg: true,
                    hasSocial: true,
                    isActive: false,
                },
                {
                    title: this.getTranslatedText("23. What are the Official Social Media Platforms of O! Millionaire"),
                    content: [
                        {
                            listSteps: [
                                {
                                    icon: 'video.svg',
                                    link: process.env.VUE_APP_YOUTUBE_URL
                                },
                                {
                                    icon: 'insta.svg',
                                    link: "https://www.instagram.com/omillionaire_me/",
                                },
                                {
                                    icon: 'fb.svg',
                                    link: "https://www.facebook.com/OMillionaire"
                                },
                                {
                                    icon: 'twitter.svg',
                                    link: process.env.VUE_APP_TWITTER_URL,
                                },
                                {
                                    icon: 'wa.svg',
                                    link: process.env.VUE_APP_WHATSAPP_URL,
                                },
                                {
                                    icon: 'tiktok.svg',
                                    link: "https://www.tiktok.com/@omillionaire_me",
                                },
                                {
                                    icon: 'linkedin.svg',
                                    link: "https://om.linkedin.com/company/omillionaire"
                                },
                                {
                                    icon: 'snapchat.svg',
                                    link: "https://www.snapchat.com/add/omillionaire_me",
                                }
                            ],
                            listStepsMb: [
                                {
                                    icon: 'video.svg',
                                    link: process.env.VUE_APP_YOUTUBE_URL
                                },
                                {
                                    icon: 'insta.svg',
                                    link: "https://www.instagram.com/omillionaire_me/",
                                },
                                {
                                    icon: 'fb.svg',
                                    link: "https://www.facebook.com/OMillionaire"
                                },
                                {
                                    icon: 'twitter.svg',
                                    link: process.env.VUE_APP_TWITTER_URL,
                                }
                            ]
                        },
                        {
                            listStepsMb: [
                                {
                                    icon: 'wa.svg',
                                    link: process.env.VUE_APP_WHATSAPP_URL,
                                },
                                {
                                    icon: 'tiktok.svg',
                                    link: "https://www.tiktok.com/@omillionaire_me",
                                },
                                {
                                    icon: 'linkedin.svg',
                                    link: "https://om.linkedin.com/company/omillionaire"
                                },
                                {
                                    icon: 'snapchat.svg',
                                    link: "https://www.snapchat.com/add/omillionaire_me",
                                }
                            ]
                        }
                    ],
                    isLast: true,
                    hasImg: true,
                    hasSocial: true,
                    isActive: false,
                },
            ],
        };
    },
    // metaInfo() {
    //     return {
    //         title: 'FAQs: Get Started with O! Millionaire for Just AED 25',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: "FAQs on O! Millionaire: Learn how to participate our weekly raffle draw for a chance to win up to AED 200 million. Dream big, participate easily, and transform your life!"
    //             }
    //         ]
    //     };
    // },
      watch: {
        screenWidth(value) {
            if (value <= 991) {
                this.isScreenMobile = true
            }
            else {
                this.isScreenMobile = false
            }
        },
    },
    created() {
        if (this.screenWidth <= 991) {
                this.isScreenMobile = true
            }
        else {
            this.isScreenMobile = false
        }
        window.addEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            this.screenWidth = window.innerWidth
        },
        handleToggleAnswer(index) {
            this.listItems.forEach((item, i) => {
                if (i === index) {
                    item.isActive = !item.isActive;
                }
            });
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/static-pages/faq.scss" />
